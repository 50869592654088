import { addGlobalLiveEventListener } from "../utils";
import { StatusClassNames } from "../types/StatusClassNames";
export default function initClampButton() {
    addGlobalLiveEventListener('click', '.clamp-button', function (event) {
        var body = document.getElementById(this.dataset.target);
        event.preventDefault();
        if (!body.classList.contains(StatusClassNames.open)) {
            body.style.maxHeight = "".concat(body.scrollHeight, "px");
            body.classList.add(StatusClassNames.open);
            this.dataset.text = this.innerText;
            this.innerText = 'Скрыть';
        }
        else {
            body.style.maxHeight = '';
            body.classList.remove(StatusClassNames.open);
            this.innerText = this.dataset.text;
        }
    });
}
