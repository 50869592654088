import { freezeHeader, unfreezeHeader } from "./headroom";
import { onScrollEnd } from "../utils";
import { useDragToScroll } from "./drag_scroll";
export default function initLetterNavigation() {
    if (!document.querySelector('.letters-nav')) {
        return;
    }
    window.addEventListener('hashchange', fixScrolling);
    document.addEventListener('DOMContentLoaded', fixScrolling);
    document.querySelectorAll('.letters-nav__link').forEach(function (letter) { return letter.addEventListener('click', fixScrolling); });
    useDragToScroll(document.querySelector('.letters-nav__wrap'));
}
function fixScrolling() {
    var link = getCurrentLink();
    if (!link || Math.abs(link.getBoundingClientRect().top) < 100) {
        return;
    }
    freezeHeader();
    onScrollEnd(unfreezeHeader);
}
function getCurrentLink() {
    var element = document.getElementById(decodeURIComponent(document.location.hash.substring(1)));
    if (!element || !element.matches('.abc-nav__anchor')) {
        return;
    }
    return element;
}
