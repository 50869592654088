var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
// сердечко на товарах
import { addGlobalLiveEventListener, extractData, getFetchOptions, startIconLoading, stopIconLoading } from "../../utils";
import ajax from "../ajax";
import { StatusClassNames } from "../../types/StatusClassNames";
import EventBus from "../../events/EventBus";
import { showModalFromHtml } from "../modal";
var list = [];
export function highlightFavorites() {
    var e_1, _a;
    if (!list.length) {
        return;
    }
    try {
        for (var _b = __values(document.querySelectorAll(".favorite-button")), _c = _b.next(); !_c.done; _c = _b.next()) {
            var el = _c.value;
            if (list.includes(parseInt(el.dataset.id))) {
                el.classList.add(StatusClassNames.filled);
            }
            else {
                el.classList.remove(StatusClassNames.filled);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export function initFavorites() {
    var _a;
    var dataEl = document.getElementById('favorites-data');
    if (dataEl) {
        list = (_a = extractData(dataEl)) !== null && _a !== void 0 ? _a : [];
        highlightFavorites();
    }
}
addGlobalLiveEventListener('click', '.favorite-button', function (e) {
    var _this = this;
    var id = parseInt(this.dataset.id);
    e.preventDefault();
    startIconLoading(this);
    ajax('/ajax/favourite', { id: id })
        .then(function (response) { return response.json(); })
        .then(function (response) {
        if (list.includes(id)) {
            _this.classList.remove(StatusClassNames.filled);
            delete list[list.indexOf(id)];
        }
        else {
            if (response.html) {
                showModalFromHtml(response.html);
            }
            _this.classList.add(StatusClassNames.filled);
            list.push(id);
            window.vkEvent(process.env.VK_PRICE_LIST_ID, 'add_to_wishlist', { products: [{ id: id }] });
        }
    })
        .finally(function () { return stopIconLoading(_this); });
});
highlightFavorites();
function onChange(event) {
    var checkbox = event.target;
    var fieldset = checkbox.closest('fieldset');
    fieldset.disabled = true;
    var body = {
        id: parseInt(checkbox.value),
        shelf: checkbox.name,
        remove: !checkbox.checked,
    };
    fetch('/api/wardrobe/append', getFetchOptions(body, true))
        .finally(function () { return fieldset.disabled = false; });
}
EventBus.on('products:load', highlightFavorites);
EventBus.on('modal:open', function (modal) {
    if (modal.id === 'modal-add-to-favourites') {
        modal.querySelectorAll('input[type=checkbox]')
            .forEach(function (checkbox) { return checkbox.addEventListener('change', onChange); });
    }
});
EventBus.on('modal:close', function (modal) {
    if (modal.id === 'modal-add-to-favourites') {
        modal.querySelectorAll('input[type=checkbox]')
            .forEach(function (checkbox) { return checkbox.removeEventListener('change', onChange); });
    }
});
