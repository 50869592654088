import { StatusClassNames } from "../types/StatusClassNames";
import { addGlobalLiveEventListener, toggleSiblingsClasses } from "../utils";
import EventBus from "../events/EventBus";
export default function initAjaxTabs() {
    addGlobalLiveEventListener('click', '.ajax_tab', function (e) {
        e.preventDefault();
        switchTab(this);
    });
}
export function switchTabByCode(code, emitEvent) {
    if (emitEvent === void 0) { emitEvent = true; }
    var tab = document.querySelector(".ajax_tab[data-md=\"".concat(code, "\"]"));
    if (tab) {
        switchTab(tab, emitEvent);
    }
}
function switchTab(tab, emitEvent) {
    if (emitEvent === void 0) { emitEvent = true; }
    if (tab.classList.contains(StatusClassNames.active)) {
        return;
    }
    toggleSiblingsClasses(tab, StatusClassNames.active, '.ajax_tab', '.tabs');
    if (!emitEvent) {
        return;
    }
    EventBus.emit('tab:change', {
        code: tab.dataset.md,
        global: tab.dataset.global === 'true',
        url: tab.href.replace(/https:\/\/[^/]+/, ''),
        pageTitle: tab.dataset.pageTitle,
    });
}
