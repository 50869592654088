import { StatusClassNames } from "../types/StatusClassNames";
var rootElement = document.querySelector('.bottom-nav');
export default function initMobileNavigation() {
    if (!rootElement) {
        return;
    }
    var buttons = rootElement.querySelectorAll('.bottom-link');
    if (!buttons.length) {
        return;
    }
    buttons.forEach(function (button) {
        var isAnchor = button.tagName === 'A';
        var targetPane = rootElement.querySelector(button.dataset.target);
        var closeButton = targetPane === null || targetPane === void 0 ? void 0 : targetPane.querySelector('.nav-box__close button');
        if (button.classList.contains(StatusClassNames.active)) {
            button.classList.add(StatusClassNames.fixed);
        }
        button.addEventListener('click', function (event) {
            var isDeactivation = isAnchor || (this.classList.contains(StatusClassNames.active) && (targetPane ? targetPane.open : true));
            resetHighlights();
            rootElement.querySelectorAll('dialog[open]')
                .forEach(function (dialog) { return closePane(dialog); });
            if (!targetPane) {
                return;
            }
            if (isDeactivation) {
                closePane(targetPane);
                return;
            }
            // close opened dialogs
            event.preventDefault();
            button.classList.add(StatusClassNames.active);
            document.body.classList.add(StatusClassNames.noscroll);
            targetPane.show();
        });
        if (!closeButton) {
            return;
        }
        closeButton.addEventListener('click', function () { return closePane(targetPane); });
    });
}
function resetHighlights() {
    rootElement.querySelectorAll(".bottom-link.".concat(StatusClassNames.active, ":not(.").concat(StatusClassNames.fixed, ")"))
        .forEach(function (button) { return button.classList.remove(StatusClassNames.active); });
}
function closePane(pane) {
    pane.close();
    resetHighlights();
    document.body.classList.remove(StatusClassNames.noscroll);
}
