import Headroom from 'headroom.js';
import { StatusClassNames } from "../types/StatusClassNames";
var headroom;
var header;
export default function initHeadroom() {
    header = document.querySelector('.header');
    if (!header) {
        return;
    }
    headroom = new Headroom(header, {
        tolerance: 25,
        classes: {
            pinned: StatusClassNames.show,
            unpinned: StatusClassNames.hide,
        },
    });
    headroom.init();
}
export function getHeight() {
    return header.clientHeight;
}
export function isSticky() {
    return getComputedStyle(header).position === 'sticky';
}
export function isFrozen() {
    return header.classList.contains('headroom--frozen');
}
export function freezeHeader() {
    if (!isFrozen()) {
        headroom.freeze();
    }
}
export function unfreezeHeader() {
    if (isFrozen()) {
        headroom.unfreeze();
    }
}
