var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { addGlobalLiveEventListener, toggleSiblingsClasses } from "../utils";
import { StatusClassNames } from "../types/StatusClassNames";
import { useDragToScroll } from "./drag_scroll";
import EventBus from "../events/EventBus";
export default function initTabs() {
    var e_1, _a;
    addGlobalLiveEventListener('click', '[data-tab-target]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.classList.contains(StatusClassNames.active)) {
            return;
        }
        var target = findTargetTab(this.dataset.tabTarget);
        if (!target) {
            return;
        }
        toggleSiblingsClasses(target, StatusClassNames.active, '.tab-content');
        toggleSiblingsClasses(this, StatusClassNames.active, '[data-tab-target]', '.tabs');
    });
    try {
        for (var _b = __values(document.querySelectorAll('.tabs__wrap')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var el = _c.value;
            useDragToScroll(el);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    EventBus.on('modal:open', function (modal) {
        var e_2, _a;
        try {
            for (var _b = __values(modal.querySelectorAll('.tabs__wrap')), _c = _b.next(); !_c.done; _c = _b.next()) {
                var el = _c.value;
                useDragToScroll(el);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    });
}
function findTargetTab(id) {
    var e_3, _a;
    try {
        for (var _b = __values(document.querySelectorAll("#".concat(id))), _c = _b.next(); !_c.done; _c = _b.next()) {
            var el = _c.value;
            if (el.parentElement.scrollWidth) {
                return el;
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
}
